<template>
  <f7-page class="profile-page">
    <template #fixed>
      <ProfileNavigationComponent type="back" :title="$t.getTranslation('LBL_COUPON')" :settings="false" />
    </template>

    <section class="profile-container">
      <div class="container">
        <f7-toolbar class="tablinks">
          <f7-link class="no-ripple" :tab-link-active="couponMode == 'available'" @click="changeCouponMode('available')">{{ $t.getTranslation("LBL_AVAILABLE") }}</f7-link>
          <f7-link class="no-ripple" :tab-link-active="couponMode == 'redeemed'" @click="changeCouponMode('redeemed')">{{ $t.getTranslation("LBL_USED") }}</f7-link>
          <f7-link class="no-ripple" :tab-link-active="couponMode == 'expired'" @click="changeCouponMode('expired')">{{ $t.getTranslation("LBL_EXPIRED") }}</f7-link>
        </f7-toolbar>

        <div class="profile-content-container">
          <template v-if="couponList && couponList.length > 0">
            <div v-for="item in couponList" :key="'coupon_' + item.UserVoucherId">
              <VoucherCardComponent :data="item"></VoucherCardComponent>
            </div>
          </template>

          <NoDataFoundComponent v-else :size="'sm'" :title="$t.getTranslation('LBL_NO_DATA_TO_BE_DISPLAYED')" />
        </div>
      </div>
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, onMounted, ref, defineAsyncComponent } from "vue";
import { get } from "@/utils/axios";
import { useStore } from "@/store";

// import ProfileNavigationComponent from "@/components/navigations/ProfileNavigationComponent.vue";
// import VoucherCardComponent from "@/components/cards/VoucherCardComponent.vue";
// import NoDataFoundComponent from "@/components/NoDataFoundComponent.vue";

const ProfileNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "profile-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/ProfileNavigationComponent.vue"));
const NoDataFoundComponent = defineAsyncComponent(() => import(/* webpackChunkName: "no-data" */ /* webpackMode: "lazy" */ "@/components/NoDataFoundComponent.vue"));
const VoucherCardComponent = defineAsyncComponent(() => import(/* webpackChunkName: "voucher-card" */ /* webpackMode: "lazy" */ "@/components/cards/VoucherCardComponent.vue"));

export default defineComponent({
  name: "ProfileCouponPage",
  components: {
    ProfileNavigationComponent,
    VoucherCardComponent,
    NoDataFoundComponent,
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const couponList = ref([]);
    const couponMode = ref("available");

    const store = useStore();
    
    const changeCouponMode = (mode) => {
      couponMode.value = mode;
      getCoupons();
    };

    const getCoupons = async () => {
      let ret = await get("/user/coupon", { mode: couponMode.value });
      couponList.value = ret?.List;
    };

    onMounted(() => {
      getCoupons();
    });

    return {
      couponList,
      couponMode,
      changeCouponMode,
    };
  },
});
</script>
